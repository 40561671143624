import { Options, Vue } from "vue-class-component";

import {
  ActiveCell,
  DynamicTable
} from "@components";
import { FilterMatchMode } from "primevue/api";
import { POSRoutesEnum } from "../router";
import { posCategoryService } from "@services/posCategory.service";
import { POSCategory } from "@/model/api/POS";

@Options({
  components: {
    DynamicTable
  }
})
export default class POSCategoryListPage extends Vue {
  readonly stateTableKey = 'POSCategoryListPage';
  filters: any = this.filtersSchema;

  get datatable(): any {
    return this.$refs.datatable;
  }

  beforeRouteLeave() {
    if (posCategoryService?.cancelPendingRequests) {
      posCategoryService.cancelPendingRequests();
    }
  }

  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith(POSRoutesEnum.CATEGORIES_PREFIX)) {
      sessionStorage.removeItem('POSCategoryListPage');
    }
    next();
  }

  get service() {
    return posCategoryService;
  }

  goAdd() {
    this.$router.push({ name: POSRoutesEnum.CATEGORIES_EDIT, params: { categoryId: 0 } });
  }

  goToDetail(model: POSCategory) {
    this.$router.push({ name: POSRoutesEnum.CATEGORIES_EDIT, params: { categoryId: model.id } });
  }

  applyFilters() {
    this.datatable.loadData();
  }

  get cols() {
    return [
      {
        field: 'name',
        header: this.$t('generic.input_name_label'),
      },
      /*
      {
        field: 'code',
        header: this.$t('admin.pos_categories.detail.internal_code_label'),
      },
      */
      {
        field: 'is_active',
        header: this.$t('generic.input_state_label'),
        style: 'min-width: 10px; max-width: 100px'
      }
    ];
  }

  get filtersSchema() {
    return {
      name: {
        matchMode: FilterMatchMode.EQUALS
      },
      note: {
        matchMode: FilterMatchMode.CONTAINS
      },
      is_active: {
        matchMode: FilterMatchMode.EQUALS
      },
      code: {
        matchMode: FilterMatchMode.EQUALS
      }
    };
  }

  get filterActiveOptions() {
    return [
      { name: this.$t('generic.all_sing_male_label'), value: null },
      { name: this.$t('active_label'), value: true },
      { name: this.$t('inactive_label'), value: false },
    ];
  }
}
