import { Options, Vue } from "vue-class-component";

import {
  ActiveCell,
  DynamicTable,
  MyAutocomplete
} from "@components";

import { FilterMatchMode } from "primevue/api";
import { candidacyService } from "@services/candidacy.service";
import { CandidacyRoutesEnum } from "../router";
import { Candidacy, CandidacyStatusEnum, candidacyStatusOptions } from "@/model/api/Candidacy";
import { Prop } from "vue-property-decorator";
import { EntityTypeEnum } from "@/model/enums/EntityTypeEnum";
import { Entity } from "@/model/api/Entity";

@Options({
  components: {
    DynamicTable,
    MyAutocomplete
  }
})
export default class CandidacyListPage extends Vue {
  @Prop()
  type!: EntityTypeEnum;

  readonly stateTableKey = 'CandidacyListPage';
  filters: any = this.filtersSchema;

  get datatable(): any {
    return this.$refs.datatable;
  }

  beforeRouteLeave() {
    if (candidacyService?.cancelPendingRequests) {
      candidacyService.cancelPendingRequests();
    }
  }

  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith(CandidacyRoutesEnum.PREFIX)) {
      sessionStorage.removeItem('CandidacyListPage');
    }
    next();
  }

  get service() {
    return candidacyService;
  }

  goAdd() {
    this.$router.push({ name: CandidacyRoutesEnum.EDIT, params: { candidacyId: 0} });
  }

  goToDetail(row: Entity) {
    this.$router.push({ name: CandidacyRoutesEnum.EDIT, params: { candidacyId: row.candidacy.id } });
  }

  onDelete(row: Entity) {
    this.$confirmMessage(this.$t("confirm_delete"))
      .then(r => {
        if (r) {
          this.deleteRow(row);
        }
      })
  }

  async onSelectCandidacyStatus({ value }, row: Entity) {
    if (value == row.candidacy.status) {
      return;
    }

    this.$waitFor(
      async () => {
        await candidacyService.setStatus(row.candidacy.id, value);
        if (value == CandidacyStatusEnum.APPROVED) {
          row.candidacy_status = CandidacyStatusEnum.APPROVED;
          row.candidacy.status = CandidacyStatusEnum.APPROVED;
        }

        this.$successMessage(this.$t('data_saved_success'));
      },
      this.$t('generic_error')
    );
  }

  private deleteRow(row: Entity) {
    this.$waitFor(
      async () => {
        await candidacyService.remove(row.candidacy);
        this.datatable.loadData();
      },
      this.$t('generic_error')
    );
  }

  applyFilters() {
    this.datatable.loadData();
  }

  customIndexReqTable(p) {
    return candidacyService.index(this.type, p);
  }

  get cols() {
    return [
      {
        field: 'business_name',
        header: this.$t('generic.business_name_label')
      },
      {
        field: 'pos_categories_text',
        header: this.$t('admin.candidacies.detail.categories_label')
      },
      /*
      {
        field: 'email',
        header: this.$t('generic.email_label'),
      },
      */
      {
        field: 'province_id',
        header: this.$t('generic.province_label')
      },
      {
        field: 'city_id',
        header: this.$t('generic.city_label')
      },
      /*
      {
        field: 'region_id',
        header: this.$t('generic.region_label')
      },
      {
        field: 'country_id',
        header: this.$t('generic.country_label')
      },
      {
        field: 'foreing_province',
        header: this.$t('generic.foreign_province_label')
      },
      {
        field: 'zip',
        header: this.$t('generic.zip_label')
      },
      */
      {
        field: 'candidacy_status',
        header: this.$t('generic.input_state_label'),
        component: ActiveCell,
        style: 'min-width: 10px; max-width: 100px'
      }
    ];
  }

  get filtersSchema() {
    return {
      business_name: {
        matchMode: FilterMatchMode.STARTS_WITH
      },
      email: {
        matchMode: FilterMatchMode.STARTS_WITH
      },
      city_id: {
        matchMode: FilterMatchMode.EQUALS
      },
      province_id: {
        matchMode: FilterMatchMode.EQUALS
      },
      region_id: {
        matchMode: FilterMatchMode.EQUALS
      },
      country_id: {
        matchMode: FilterMatchMode.EQUALS
      },
      foreing_province: {
        matchMode: FilterMatchMode.STARTS_WITH
      },
      zip: {
        matchMode: FilterMatchMode.EQUALS
      },
      candidacy_status: {
        matchMode: FilterMatchMode.EQUALS
      },
      pos_categories_text: {
        matchMode: FilterMatchMode.EQUALS,
        value: null
      }
    };
  }

  get filterCandidacyStatusOptions() {
    return candidacyStatusOptions;
  }

  get candidacyStatusOptions() {
    return candidacyStatusOptions.filter(x => x.code);
  }
}
