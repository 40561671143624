import { classToPlain, plainToClass } from 'class-transformer';
import { FormPage, POSForm } from '@components';

import {
  Form as VForm,
  Field as VField
} from 'vee-validate';
import { Options } from "vue-class-component";
import { Prop } from 'vue-property-decorator';
import { POS } from '@/model/api/POS';
import { Network } from "@/model/api/Network";
import { posService } from '@services/pos.service';
import moment from 'moment';

@Options({
  components: {
    VForm,
    VField,
    POSForm
  }
})
export default class POSEditPage extends FormPage {
  @Prop() readonly posId!: number;

  model: POS = null;

  isLoading = false;

  get isNew() {
    return this.posId == 0;
  }

  beforeRouteLeave(to, from, next) {
    this.checkBeforeRouteLeave(next);
  }

  async created() {    
    if (this.isNew) {
      this.model = new POS();
      this.model.is_active = true;
    } else {
      this.isLoading = true;
      try {
        this.model = plainToClass(POS, await posService.getById(this.posId));
      } catch (e) {
        this.$router.back();
      }
      this.isLoading = false;
    }

    this.modelFormChanged();
  }

  async doSave() {
    const now = new Date().toISOString();
    
    if (this.model.start_contract_at && moment(this.model.start_contract_at).startOf('day').toISOString() >= now) { this.$errorMessage(this.$t('generic_error')); return false;}
    if (this.model.start_contract_at && this.model.end_contract_at && this.model.start_contract_at >= this.model.end_contract_at) { this.$errorMessage(this.$t('generic_error')); return false;}
    if (this.model.start_contract_at && this.model.end_contract_at && this.model.apply_commissions_at && (this.model.apply_commissions_at < this.model.start_contract_at || this.model.apply_commissions_at > this.model.end_contract_at)) { this.$errorMessage(this.$t('generic_error')); return false;}
  
    await this.$waitFor(
      async () => {
        if (this.isNew) {          
          await posService.create(classToPlain(this.model) as any);
        } else {
          await posService.updatePatch(classToPlain(this.model) as any);
        }

        this.modelFormChanged();

        this.$successMessage(this.$t('data_saved_success'));
        this.$router.back();
      },
    );
  }
}