import { Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import {
  Form as VForm,
  Field as VField
} from 'vee-validate';
import { FormPage } from "@components";
import { POSCategory } from "@/model/api/POS";
import { posCategoryService } from "@services/posCategory.service";

@Options({
  components: {
    VForm,
    VField,
  }
})
export default class POSCategoryEditPage extends FormPage {
  @Prop() readonly categoryId!: number;

  model: POSCategory = {
    name: null,
    is_active: true
  };

  isLoading = false;

  get isNew() {
    return this.categoryId == 0;
  }

  beforeRouteLeave(to, from, next) {
    this.checkBeforeRouteLeave(next);
  }

  async created() {
    if (!this.isNew) {
      this.isLoading = true;
      try {
        this.model = await posCategoryService.getById(this.categoryId)
      } catch (e) {
        this.$router.back();
      }
      this.isLoading = false;
    }

    this.modelFormChanged();
  }

  async doSave() {
    await this.$waitFor(
      async () => {
        if (this.isNew) {
          await posCategoryService.create(this.model);
        } else {
          await posCategoryService.update(this.model);
        }
        this.modelFormChanged();

        this.$successMessage(this.$t('data_saved_success'));
        this.$router.back();
      },
      this.$t('generic_error')
    )
  }
}