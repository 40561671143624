import { BaseApiModel, IBaseApiAttachmentModel } from "../common/BaseApiModel";
import { TimeSlot, VAT } from "./Domain";
import { Entity } from "./Entity";
import { Network } from "./Network";
import { Order } from "./Order";
import { Provider, ProviderService } from "./Provider";
import { Service } from "./Service";

export class POS extends Entity {
    pos_categories: POSCategory[] = [];
    networks: Network[] = [];

    readonly pos_categories_text?: string = null;
    readonly attachments: IBaseApiAttachmentModel[] = [];

    get posCategoriesLabel(): string {
        return this.pos_categories?.map(pc => pc.name).join(' - ') || '-';
    }
}

export class POSCategory extends BaseApiModel {
    name: string;
    note?: string = null;
    is_active: boolean = true;
    code?: string = null;
}

export class POSPortfolio extends BaseApiModel {
    service_id: number = null;
    type: string = null;
    is_configured: boolean = true;
    is_active: boolean = false;
    gross_price: number = 0;
    provider_owner_id: number = null;
    order_id: number = null;
    service_date_at: string = null;
    service_time_from_at: string = null;
    service_time_to_at: string = null;
    provider_service_id: number = null;
    provider_service: ProviderService = null;
    provider_owner: Provider = null;
    service: Service = null;
    time_slots: TimeSlot[] = [];
    pos_service_id: number = null;
    pos_service: POSService = new POSService();
    service_from_date_at: string = null;
    service_to_date_at: string = null;
    weekdays: number[] = [];
    excluded_dates: POSPortfolioExcludedDate[] = [];
    order_code: string = null;
    disabled: boolean = null;
    order: Order = null;
    cust_att: IBaseApiAttachmentModel[] = [];
    exam_att: IBaseApiAttachmentModel[] = [];
    serv_att: IBaseApiAttachmentModel[] = [];

    readonly feedback_service: number = null;
}

export class POSService extends BaseApiModel {
    entity_id: number = null;
    service_id: number = null;
    is_active: boolean = true;
    duration: number = null;
    description: string = null;

    service: Service = new Service();
    attachments: IBaseApiAttachmentModel[] = [];
}

export class POSPortfolioExcludedDate extends BaseApiModel {
    pos_portfolio_id?: number;
    date_at: string;
}