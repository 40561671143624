import { classToPlain, plainToClass } from 'class-transformer';
import { FormPage, POSForm } from '@components';

import {
  Form as VForm,
  Field as VField
} from 'vee-validate';
import { Options } from "vue-class-component";
import { Prop } from 'vue-property-decorator';
import { POS } from '@/model/api/POS';
import { posService } from '@services/pos.service';
import { Candidacy, CandidacyStatusEnum, candidacyStatusOptions } from '@/model/api/Candidacy';
import { Entity } from '@/model/api/Entity';
import { candidacyService } from '@services/candidacy.service';
import { entityService } from '@services/entity.service';
import { EntityTypeEnum } from '@/model/enums/EntityTypeEnum';

@Options({
  components: {
    VForm,
    VField,
    POSForm
  }
})
export default class CandidacyEditPage extends FormPage {
  @Prop() readonly candidacyId!: number;
  @Prop() readonly type!: EntityTypeEnum;

  model: Entity = null;
  candidacy: Candidacy = null;

  isLoading = false;

  get isNew() {
    return this.candidacyId == 0;
  }

  beforeRouteLeave(to, from, next) {
    this.checkBeforeRouteLeave(next);
  }

  async created() {
    if (this.isNew) {
      this.model = new Entity();
      this.model.type = this.type;
      this.model.candidacy_status = CandidacyStatusEnum.WAITING;

      this.candidacy = new Candidacy();
      this.candidacy.type = this.type;
      this.candidacy.status = CandidacyStatusEnum.WAITING;
    } else {
      this.isLoading = true;
      try {
        this.candidacy = plainToClass(Candidacy, await candidacyService.getById(this.candidacyId));
        if (this.candidacy.type == EntityTypeEnum.POS) {
          this.model = plainToClass(Entity, await posService.getById(this.candidacy.entity_id));
        }
      } catch (e) {
        this.$router.back();
      }
      this.isLoading = false;
    }

    this.modelFormChanged();
  }

  async doSave() {
    await this.$waitFor(
      async () => {
        if (this.candidacy.type == EntityTypeEnum.POS) {
          if (this.isNew) {
            this.candidacy = await candidacyService.create(classToPlain(this.model) as any);
          } else {
            this.candidacy = await candidacyService.updatePatch({ ...classToPlain(this.model), id: this.candidacy.id } as any);
          }
        }

        if (this.model.candidacy_status != this.candidacy.status) {
          await candidacyService.setStatus(this.candidacy.id, this.model.candidacy_status);
        }

        this.modelFormChanged();

        this.$successMessage(this.$t('data_saved_success'));
        this.$router.back();
      },
      this.$t('generic_error')
    );
  }

  get candidacyStatusOptions() {
    return candidacyStatusOptions.filter(x => x.code);
  }
}