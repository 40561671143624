import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "candidacy_edit_page",
  class: "page standard vertical-form"
}
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = { class: "actions" }
const _hoisted_5 = { class: "content card" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "p-field p-col-3" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InfoIcon = _resolveComponent("InfoIcon")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_POSForm = _resolveComponent("POSForm")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("section", _hoisted_2, [
      _createVNode("div", null, [
        _createVNode("h1", null, _toDisplayString(_ctx.$t('admin.candidacies.pos.detail.title')), 1),
        _createVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('admin.candidacies.detail.subtitle')), 1)
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_Button, {
          label: _ctx.$t('btn_save_label'),
          class: "p-button-success p-ml-auto",
          icon: "pi pi-save",
          iconPos: "right",
          disabled: _ctx.requestPending,
          type: "submit",
          form: "edit_form"
        }, null, 8, ["label", "disabled"])
      ])
    ]),
    _createVNode("section", _hoisted_5, [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock("h3", _hoisted_6, _toDisplayString(_ctx.$t('loading_label')), 1))
        : _createCommentVNode("", true),
      (_ctx.model && _ctx.candidacy)
        ? (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
            _createVNode("div", _hoisted_7, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('admin.candidacies.detail.status_candidacy_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "admin.candidacies.detail.status_candidacy_label.tooltip" })
              ]),
              (!_ctx.candidacy.isApproved)
                ? (_openBlock(), _createBlock(_component_Dropdown, {
                    key: 0,
                    modelValue: _ctx.model.candidacy_status,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.model.candidacy_status = $event)),
                    options: _ctx.candidacyStatusOptions,
                    optionLabel: "name",
                    optionValue: "code",
                    disabled: _ctx.requestPending
                  }, null, 8, ["modelValue", "options", "disabled"]))
                : (_openBlock(), _createBlock("span", _hoisted_8, _toDisplayString(_ctx.candidacy.statusOption.name || '-'), 1))
            ]),
            _createVNode(_component_Divider),
            _createVNode(_component_POSForm, {
              model: _ctx.model,
              onSubmit: _ctx.doSave,
              readOnly: _ctx.candidacy.isApproved,
              "show-status-field": false
            }, null, 8, ["model", "onSubmit", "readOnly"])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _createVNode("section", _hoisted_9, [
      _createVNode(_component_Button, {
        label: _ctx.$t('btn_go_back_label'),
        class: "p-button-secondary",
        icon: "pi pi-arrow-left",
        iconPos: "left",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.back()))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('btn_save_label'),
        class: "p-button-success p-ml-auto",
        icon: "pi pi-save",
        iconPos: "right",
        disabled: _ctx.requestPending,
        type: "submit",
        form: "edit_form"
      }, null, 8, ["label", "disabled"])
    ])
  ]))
}