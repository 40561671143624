import { Candidacy, CandidacyStatusEnum } from '@/model/api/Candidacy';
import { Entity } from '@/model/api/Entity';
import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { EntityTypeEnum } from '@/model/enums/EntityTypeEnum';
import axios from 'axios';
import { plainToClass } from 'class-transformer';
import { CRUD } from './base/crud';

class CandidacyService extends CRUD<any, any> {
    readonly endPoint = `candidacies`;

    // override
    public async index(type: EntityTypeEnum, params?: any) {
        if (params.filters) {
            params.filters = this.getFilterParam(params.filters);
        }
        params = { ...params, type };

        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;
        const res = await this.get<PaginatedResponse<Entity>>(`${this.endPoint}`, { params, cancelToken });
        res.data = plainToClass(Entity, res.data);
        return res;
    }

    // override
    public create(item: Entity) {
        return this.post<Candidacy>(`${this.endPoint}`, item);
    }

    // override
    public update(item: Entity) {
        return this.put<Candidacy>(`${this.endPoint}/${item.id}`, item);
    }

    // override
    public updatePatch(item: Entity) {
        return this.patch<Candidacy>(`${this.endPoint}/${item.id}`, item);
    }

    // override
    public getById(id: number){
        return this.get<Candidacy>(`${this.endPoint}/${id}`);
    }

    public setStatus(candidacyId: number, status: CandidacyStatusEnum) {
        return this.post<void>(`${this.endPoint}/${candidacyId}/set-status`, { status });
    }
}

export const candidacyService = new CandidacyService();