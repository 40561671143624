import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "pos_area_page",
  class: "page standard vertical-form"
}
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = { class: "actions" }
const _hoisted_5 = {
  key: 0,
  class: "content card"
}
const _hoisted_6 = { class: "content card" }
const _hoisted_7 = { class: "list-attachments" }
const _hoisted_8 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_POSForm = _resolveComponent("POSForm")!
  const _component_InfoIcon = _resolveComponent("InfoIcon")!
  const _component_FileUploadDialog = _resolveComponent("FileUploadDialog")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("section", _hoisted_2, [
      _createVNode("div", null, [
        _createVNode("h1", null, _toDisplayString(_ctx.$t('pos.profile.title')), 1),
        _createVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('pos.profile.subtitle')), 1)
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_Button, {
          label: _ctx.$t('profile.btn_change_password_label'),
          class: "p-button-secondary",
          icon: "pi pi-key",
          iconPos: "right",
          onClick: _ctx.goChangePassword
        }, null, 8, ["label", "onClick"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('btn_save_label'),
          class: "p-button-success",
          icon: "pi pi-save",
          iconPos: "right",
          disabled: _ctx.requestPending,
          type: "submit",
          form: "edit_form"
        }, null, 8, ["label", "disabled"])
      ])
    ]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock("section", _hoisted_5, [
          _createVNode("h3", null, _toDisplayString(_ctx.$t('loading_label')), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.model)
      ? _withDirectives((_openBlock(), _createBlock(_component_POSForm, {
          key: 1,
          model: _ctx.model,
          onSubmit: _ctx.doSave
        }, null, 8, ["model", "onSubmit"])), [
          [_vShow, !_ctx.isLoading]
        ])
      : _createCommentVNode("", true),
    _withDirectives(_createVNode("section", _hoisted_6, [
      _createVNode("label", null, [
        _createTextVNode(_toDisplayString(_ctx.$t('pos.profile.docs_for_customer_label')) + " ", 1),
        _createVNode(_component_InfoIcon, { text: "pos.profile.docs_for_customer_label.tooltip" })
      ]),
      _createVNode("div", _hoisted_7, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.attachments, (attach, idx) => {
          return (_openBlock(), _createBlock("div", {
            class: "attachment",
            key: `attach-${idx}`
          }, [
            _createVNode("a", {
              href: `${attach.path_url}?filename=${attach.filename}`,
              target: "_blank"
            }, _toDisplayString(attach.description || attach.filename), 9, ["href"]),
            _createVNode(_component_Button, {
              class: "p-button-text",
              icon: "pi pi-times-circle",
              onClick: ($event: any) => (_ctx.deleteAttachment(attach)),
              disabled: _ctx.requestPending
            }, null, 8, ["onClick", "disabled"])
          ]))
        }), 128)),
        _createVNode(_component_Button, {
          label: _ctx.$t('btn_attachments_label'),
          class: "p-button-warning",
          icon: "pi pi-plus",
          iconPos: "left",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showFileUploadDialog()))
        }, null, 8, ["label"])
      ])
    ], 512), [
      [_vShow, !_ctx.isLoading]
    ]),
    _createVNode("section", _hoisted_8, [
      _createVNode(_component_Button, {
        label: _ctx.$t('btn_go_back_label'),
        class: "p-button-secondary",
        icon: "pi pi-arrow-left",
        iconPos: "left",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.back()))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('btn_save_label'),
        class: "p-button-success p-ml-auto",
        icon: "pi pi-save",
        iconPos: "right",
        disabled: _ctx.requestPending,
        type: "submit",
        form: "edit_form"
      }, null, 8, ["label", "disabled"])
    ]),
    _createVNode(_component_FileUploadDialog, {
      visible: _ctx.displayFileUploadDialog,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (_ctx.displayFileUploadDialog = $event)),
      object_id: _ctx.model?.id,
      attachment_type: _ctx.attachment_type,
      attachment_id: _ctx.attachment_id,
      onSuccess: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateAttachments($event))),
      maxFileSize: _ctx.maxFileSize
    }, null, 8, ["visible", "object_id", "attachment_type", "attachment_id", "maxFileSize"])
  ]))
}