import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "entity_edit_page",
  class: "page standard vertical-form"
}
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = { class: "actions" }
const _hoisted_5 = {
  key: 0,
  class: "toolbar"
}
const _hoisted_6 = { class: "subtitle" }
const _hoisted_7 = { class: "subtitle" }
const _hoisted_8 = { class: "subtitle" }
const _hoisted_9 = { class: "content card" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_POSForm = _resolveComponent("POSForm")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("section", _hoisted_2, [
      _createVNode("div", null, [
        _createVNode("h1", null, _toDisplayString(_ctx.$t('admin.pos.detail.title')), 1),
        _createVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('admin.pos.detail.subtitle')), 1)
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_Button, {
          label: _ctx.$t('btn_save_label'),
          class: "p-button-success",
          icon: "pi pi-save",
          iconPos: "right",
          disabled: _ctx.requestPending,
          type: "submit",
          form: "edit_form"
        }, null, 8, ["label", "disabled"])
      ])
    ]),
    (!_ctx.isNew)
      ? (_openBlock(), _createBlock("section", _hoisted_5, [
          _createVNode("button", null, _toDisplayString(_ctx.$t('admin.pos.detail.btn_feedback_customer.label')), 1),
          _createVNode("button", null, [
            _createTextVNode(_toDisplayString(_ctx.$t('admin.pos.detail.btn_orders_pos.label')) + " ", 1),
            _createVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('admin.pos.detail.btn_customer_orders.subtitle')), 1)
          ]),
          _createVNode("button", null, [
            _createTextVNode(_toDisplayString(_ctx.$t('admin.pos.detail.btn_pos_payments_status.label')) + " ", 1),
            _createVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('admin.pos.detail.btn_pos_payments_status.subtitle')), 1)
          ]),
          _createVNode("button", null, [
            _createTextVNode(_toDisplayString(_ctx.$t('admin.pos.detail.btn_pos_purchases.label')) + " ", 1),
            _createVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('admin.pos.detail.btn_pos_purchases.subtitle')), 1)
          ]),
          _createVNode("button", null, _toDisplayString(_ctx.$t('admin.pos.detail.btn_calendar.label')), 1),
          _createVNode("button", null, _toDisplayString(_ctx.$t('admin.pos.detail.btn_pos_services.label')), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode("section", _hoisted_9, [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock("h3", _hoisted_10, _toDisplayString(_ctx.$t('loading_label')), 1))
        : _createCommentVNode("", true),
      (_ctx.model)
        ? (_openBlock(), _createBlock(_component_POSForm, {
            key: 1,
            model: _ctx.model,
            onSubmit: _ctx.doSave
          }, null, 8, ["model", "onSubmit"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode("section", _hoisted_11, [
      _createVNode(_component_Button, {
        label: _ctx.$t('btn_go_back_label'),
        class: "p-button-secondary",
        icon: "pi pi-arrow-left",
        iconPos: "left",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.back()))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('btn_save_label'),
        class: "p-button-success p-ml-auto",
        icon: "pi pi-save",
        iconPos: "right",
        disabled: _ctx.requestPending,
        type: "submit",
        form: "edit_form"
      }, null, 8, ["label", "disabled"])
    ])
  ]))
}