import { classToPlain, plainToClass } from 'class-transformer';
import { FileUploadDialog, FormPage, POSForm } from '@components';
import {
  Form as VForm,
  Field as VField,
  ErrorMessage as VError
} from 'vee-validate';
import { Options } from "vue-class-component";
import { authStore } from '@/modules/auth/store';
import { AuthRoutesEnum } from '@/modules/auth/router';
import { POS } from '@/model/api/POS';
import { posService } from '@services/pos.service';
import { IBaseApiAttachmentModel } from '@/model/common/BaseApiModel';
import { entityService } from '@services/entity.service';
import { AttachmentIdEnum, AttachmentTypeEnum } from '@/model/enums/AttachmentTypeEnum';

@Options({
  components: {
    VForm,
    VField,
    VError,
    POSForm,
    FileUploadDialog
  }
})
export default class POSAreaPage extends FormPage {

  attachment_type: AttachmentTypeEnum = AttachmentTypeEnum.GENERIC;
  attachment_id: AttachmentIdEnum = AttachmentIdEnum.ENTITY;
  model: POS = null;
  attachments: IBaseApiAttachmentModel[] = [];
  
  isLoading = false;
  displayFileUploadDialog: boolean = false;

  get me() {
    return authStore.getters.me;
  }

  beforeRouteLeave(to, from, next) {
    this.checkBeforeRouteLeave(next);
  }

  async created() {
    this.isLoading = true;
    try {
      this.model = plainToClass(POS, await posService.getById(this.me.entity_id));
      this.attachments = await entityService.getAttachments(this.me.entity_id, AttachmentTypeEnum.GENERIC);
      this.modelFormChanged();
    } catch (e) {
      this.$router.back();
    }
    this.isLoading = false;
  }

  async doSave() {
    await this.$waitFor(
      async () => {
        await posService.updatePatch(classToPlain(this.model) as any);
        await authStore.actions.getMe();

        this.modelFormChanged();
        this.$successMessage(this.$t('data_saved_success'));
        this.$router.back();
      },
      this.$t('generic_error')
    );
  }

  updateAttachments(attach: any) {
    this.attachments.push(attach);
    this.$successMessage(this.$t('upload_success'));
  }

  maxFileSize() {
    return process.env.MAX_FILE_SIZE || 10000000;
  }

  deleteAttachment(attach: IBaseApiAttachmentModel) {
    this.$waitFor(async () => {
      await entityService.deleteAttachment(attach.id);
      this.attachments = this.attachments.filter(a => a.id != attach.id);

      this.$successMessage(this.$t('upload_deleted_success'));
    });
  }

  goChangePassword() {
    this.$router.push({ name: AuthRoutesEnum.CHANGE_PASSWORD });
  }

  showFileUploadDialog() {
    this.displayFileUploadDialog = true; 
  }

  checkContent(event: any) {
    console.log(event.textValue.length);    
    console.log(event.delta.ops[1].insert);    
    if (event.textValue.length > 10) {
      this.model.note = 'ciuppa';
    }
  }
}